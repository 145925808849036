img.logo {
    width: 7em;
    height: 7em;
    margin-top: -1em; /* Compensate outer padding */
    display: inline-block;
}

.logoContainer {
    margin-right: 4em;
    margin-bottom: 2em;
}