.langSwitch {
    font-size: 0.875rem;
    margin-top: 0.5em;
    margin-left: 0.5em;
    white-space: nowrap;
}

.active {
    font-weight: bold;
}

.active::after {
    content: " ✓";
}

.inactive {
    opacity: 0.5;
}

.inactive:hover {
    opacity: 1;
}