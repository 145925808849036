.pawsCard {
    min-height: 100%;
}

div.pawsCardTopImageWrap {
    max-height: 10em;
    object-fit: cover;
    overflow: hidden;
}

img.pawsCardTopImage {
    width: 100%;
}

img.pawsCardImage {
    max-width: 12em;
    height: 12em;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
}

.pawsCardTitle {
}

/*.pin-button {*/
/*    position: absolute; !* set the position of the button *!*/
/*    bottom: 16pt; !* pin the button to the bottom of the container *!*/
/*    left: 50%; !* position the button in the center horizontally *!*/
/*    transform: translateX(-50%); !* center the button *!*/
/*    width: 90%; !* set the width of the button to the width of the container *!*/
/*}*/

.pawsCardBody {
    background: white;
}

.pawsCardText {
    margin-bottom: 24pt;
}

img.desaturated {
    opacity: 0.25;
}
