li.menuDropdownItem {
    display: block;
    width: 100%;
}

/* Link style */
a.pawsNavLink {
    display: inline-block;
    color: black;
    border-radius: 0.25em;
    margin-right: 1em;
    padding: 0.25em;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
}

a.pawsNavLink:hover {
    background: #ccffcc;
}

a.pawsCurrentPage {
    background-color: #3f8a33 !important;
    color: white;
}

a.pawsCurrentPage:hover {
    color: white;
}
