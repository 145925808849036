@media screen and (min-width: 900px) {
    .pawsMain {
        padding-left: 6em !important;
        padding-right: 6em !important;
    }
}

@media screen and (max-width: 900px) {
    .pawsMain {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

/*
* Image Styles
*/

/* Для зображень в контенті */
img.textInline {
    display: inline-block;
    width: 1.5em;
    margin-bottom: 0.25em;
}

/* Для зображень в футері */
img.footerInline {
    display: inline-block;
    width: 2em;
}

/* Для кольорових зображень, які мають бути чорно-білими */
img.black-and-white {
    filter: grayscale(100%);
}

img.contactIcon {
    margin-right: 0.5em;
}

/* Table styles */
table.tab {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1em;
}

table.tab thead {
    background-color: #aaa;
    font-weight: bold;
    position: sticky;
    top: 0;
}

table.tab thead tr td {
    padding: 0.5em;
    text-align: center;
}

table.tab tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

table.tab tbody tr td.number {
    padding: 0.5em;
    text-align: right;
}

table.tab tfoot tr td {
    font-weight: bold;
}

table.tab tfoot tr td.number {
    background: #ccffcc;
    padding: 0.5em;
    text-align: right;
}
